import React, { useRef, useState } from "react";
import { doc, setDoc, updateDoc, collection, getDocs } from "firebase/firestore"
import { db } from "../firebase"
import { useDb } from "../contexts/DbContext"
import { useAuth } from "../contexts/AuthContext"
import { UilExclamationTriangle, UilCheck, UilArrowLeft, UilArrowRight } from "@iconscout/react-unicons"
import { Link } from "react-router-dom"

export default function Admin() {
    const paymentLinkCollectionRef = collection(db, "payment-links")
    const { getPaymentLink } = useDb()
    const { isRegistered } = useAuth()
    const emailRef = useRef()
    const urlRef = useRef()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        const emailValue = emailRef.current.value
        const urlValue = urlRef.current.value

        const isEmailRegistered = await isRegistered(emailValue)

        if (isEmailRegistered[0] !== undefined) {
            getPaymentLink(emailValue).then(async res => {
                if (res === undefined) { //If payment link doesn't exist for this email
                    try {
                        setError("")
                        setLoading(true)
                        await setDoc(doc(paymentLinkCollectionRef), {email: emailValue, link: urlValue})
                        setMessage("Le lien de paiement a bien été envoyé")
                    } catch {
                        setError("L'envoie du lien de paiement a échoué")
                    }
                } else { //If payment link exist for this email
                    setError("")
                    setLoading(true)
                    getPaymentLink(emailValue).then(async res => {
                        try {
                            await updateDoc(doc(db, "payment-links", res.id), {link: urlValue})
                            setMessage("Le lien de paiement a bien été modifié")
                        } catch {
                            setError("La modification du lien de paiement a échoué")
                        }
                    })
                }
            })
        } else {
            setError("L'adresse email renseignée ne correspond à aucun utilisateur")
        }

        setLoading(false)
    }

    return (
        <main className="registration-main">
            <Link className="registration-home-link" to="/"><UilArrowLeft /></Link>
            <div className="registration-container">
                <h2>Envoyer un lien de paiement</h2>
                {error && <p className="error"><UilExclamationTriangle/>{error}</p>}
                {message && <p className="message"><UilCheck/>{message}</p>}
                <form action="" onSubmit={handleSubmit}>
                    <label>
                        Adresse email du client
                        <input type="email" name="" id="emailInput" placeholder="Adresse email du client" ref={emailRef} required/>
                    </label>
                    <label>
                        Lien de paiement
                        <input type="url" name="" id="urlInput" placeholder="Lien de paiement" ref={urlRef} required/>
                    </label>
                    <button disabled={loading} type="submit">Envoyer<span><UilArrowRight /></span></button>
                </form>
            </div>
        </main>
    )
}
