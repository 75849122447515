import React from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import { useDb } from "../../contexts/DbContext"

export default function PaymentRoute({ children }) {
    const { currentUser } = useAuth()
    const { getPaymentLink } = useDb()

    async function paymentLink() {
        if (currentUser) {
            const res = await getPaymentLink(currentUser.email)
            return res.data.link
        }
    }

    return currentUser && paymentLink() !== undefined ? children : <Navigate to="/" />
}