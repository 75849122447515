import React, { useState, useEffect } from "react"
import { gsap } from "gsap"
import Logo from "../images/logo.svg"
import { UilUser, UilArrowRight } from "@iconscout/react-unicons"
import Robe from "../images/robe-rouge.png"
import { Link } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { useDb } from "../contexts/DbContext"

export default function Landing(props) {
    const { currentUser, logout } = useAuth()
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
    const [error, setError] = useState("")
    const { getPaymentLink } = useDb()
    const [paymentLink, setPaymentLink] = useState()

    useEffect(() => {
        if (currentUser) {
            getPaymentLink(currentUser.email)
                .then(res => {
                    if (res !== undefined) {
                        setPaymentLink(res.data.link)
                    }
                })
        }
    }, [])

    function toggleUserMenu() {
        if (!isUserMenuOpen) {
            gsap.to(".user-menu-container svg", {
                color: "var(--red-color)",
                duration: 0,
                delay: 0
            })
            gsap.to(".user-menu", {
                display: "flex",
                delay: 0,
                duration: 0,
                onComplete: () => {
                    gsap.to(".user-menu", {
                        opacity: 1,
                        duration: .3
                    })
                }
            })
        } else {
            gsap.to(".user-menu-container svg", {
                color: "var(--pink-color)",
                delay: 0,
                duration: 0
            })
            gsap.to(".user-menu", {
                opacity: 0,
                duration: .3,
                onComplete: () => {
                    gsap.to(".user-menu", {
                        display: "none",
                        duration: 0
                    })
                }
            })
        }
        setIsUserMenuOpen(prevState => !prevState)
    }

    async function handleLogout() {
        setError("")

        try {
            await logout()
        } catch {
            setError("La tentative de déconnexion a échoué")
        }
    }

    return (
        <div className="landing">
            <nav>
                <>
                    <img src={Logo} alt="Logo" />
                    <div className="user-menu-container" onClick={toggleUserMenu}>
                        <UilUser />
                        {
                        currentUser
                        ? <div className="user-menu">
                            {currentUser.uid === process.env.REACT_APP_FIREBASE_ADMIN && <Link to="/admin">Admin</Link>}
                            <Link to="/profil">Mon profil<UilArrowRight /></Link>
                            {paymentLink !== undefined && <Link to="/paiement">Mon paiement<UilArrowRight /></Link>}
                            <p onClick={handleLogout}>Me déconnecter<UilArrowRight /></p>
                        </div>
                        : <div className="user-menu">
                            <Link to="/inscription">M'inscrire<UilArrowRight /></Link>
                            <Link to="/connexion">Me connecter<UilArrowRight /></Link>
                        </div>
                        }
                    </div>
                </>
            </nav>
            <main>
                {
                props.phone
                ? <img src={Robe} className="robe" alt="Robe rouge"/>
                : <>
                    <img src={Robe} className="robe" alt="Robe rouge"/>
                    <img src={Logo} className="logo" alt="Logo" />
                </>
                }
            </main>
        </div>
    )
}