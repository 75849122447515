import React, { useRef, useState } from "react";
import { UilExclamationTriangle, UilArrowLeft, UilArrowRight } from "@iconscout/react-unicons"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function Profile() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updatePassword, updateEmail, logout } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    function handleSubmit(e) {
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Les mots de passe ne correspondent pas")
        }
    
        const promises = []
        setLoading(true)
        setError("")
    
        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value))
        }
        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value))
        }
    
        Promise.all(promises)
        .then(() => {
            navigate("/")
        })
        .catch(() => {
            setError("La mise à jour du profil a échoué")
        })
        .finally(() => {
            setLoading(false)
        })
    }

    async function handleLogout() {
        setError("")

        try {
            await logout()
            navigate("/connexion")
        } catch {
            setError("La tentative de déconnexion a échoué")
        }
    }

    return (
        <main className="registration-main">
            <Link className="registration-home-link" to="/"><UilArrowLeft /></Link>
            <div className="registration-container">
                <h2>Mettre à jour le profil</h2>
                {error && <p className="error"><UilExclamationTriangle/>{error}</p>}
                <form action="" onSubmit={handleSubmit}>
                    <label>
                        Adresse email
                        <input type="email" name="" id="emailInput" placeholder="Adresse email" ref={emailRef} defaultValue={currentUser.email} required/>
                    </label>
                    <label>
                        Mot de passe
                        <input type="password" name="" id="passwordInput" placeholder="Laissez le champ vide pour ne pas changer" ref={passwordRef} required/>
                    </label>
                    <label>
                        Confirmation du mot de passe
                        <input type="password" name="" id="passwordConfirmInput" placeholder="Laissez le champ vide pour ne pas changer" ref={passwordConfirmRef} required/>
                    </label>
                    <button disabled={loading} type="submit">Mettre à jour<span><UilArrowRight /></span></button>
                </form>
            </div>
            <div className="registration-link">
                <a onClick={handleLogout}>Me déconnecter</a>
            </div>
        </main>
    )
}
