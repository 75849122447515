import React, { useState, useEffect } from "react"
import { UilArrowLeft } from "@iconscout/react-unicons"
import { useAuth } from "../contexts/AuthContext"
import { useDb } from "../contexts/DbContext"
import { Link } from "react-router-dom"

export default function Payment() {
    const { currentUser } = useAuth()
    const { getPaymentLink } = useDb()
    const [paymentLink, setPaymentLink] = useState()

    useEffect(() => {
        if (currentUser) {
            getPaymentLink(currentUser.email)
                .then(res => {
                    if (res !== undefined) {
                        setPaymentLink(res.data.link)
                    }
                })
        }
    }, [])

    return (
        <main className="registration-main">
            <Link className="registration-home-link" to="/"><UilArrowLeft /></Link>
            <div className="registration-container">
                <h2>Payer ma robe</h2>
                <p>Pour payer et ainsi confirmer votre commande, veuillez cliquer sur le bouton ci-dessous.</p>
                <a href={paymentLink} target="_blank" rel="noopener noreferrer" className="registration-payment-link">Payer</a>
            </div>
        </main>
    )
}
