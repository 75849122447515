import React, { useRef, useState } from "react"
import { UilExclamationTriangle, UilCheck, UilArrowRight } from '@iconscout/react-unicons'
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage("")
            setError("")
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage("Un email vous a été envoyé comportant les instructions nécessaires pour réinitialiser votre mot de passe")
        } catch {
            setError("la réinitialisation de votre mot de passe a échoué")
        }

        setLoading(false)
    }

    return (
        <main className="registration-main">
            <div className="registration-container">
                <h2>Réinitialisation du mot de passe</h2>
                {error && <p className="error"><UilExclamationTriangle/>{error}</p>}
                {message && <p className="message"><UilCheck/>{message}</p>}
                <form action="" onSubmit={handleSubmit}>
                    <label>
                        Adresse email
                        <input type="email" name="" id="emailInput" placeholder="Adresse email" ref={emailRef} required/>
                    </label>
                    <button disabled={loading} type="submit">Réinitialiser<span><UilArrowRight /></span></button>
                </form>
            </div>
            <div className="registration-link">
                Pas encore de compte ? <Link to="/inscription">Inscription</Link>
            </div>
        </main>
    )
}