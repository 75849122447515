import React from "react"
import Landing from "./components/Landing"
import Form from "./components/Form"
import ChooseDress from "./components/ChooseDress"
import Presentation from "./components/Presentation"
import Footer from "./components/Footer"
import Signup from "./components/Signup"
import Login from "./components/Login"
import ForgotPassword from "./components/ForgotPassword"
import Profile from "./components/Profile"
import Admin from "./components/Admin"
import Payment from "./components/Payment"
import { AuthProvider } from "./contexts/AuthContext"
import { DbProvider } from "./contexts/DbContext"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import PublicRoute from "./components/routing/PublicRoute"
import PrivateRoute from "./components/routing/PrivateRoute"
import AdminRoute from "./components/routing/AdminRoute"
import PaymentRoute from "./components/routing/PaymentRoute"

export default function App() {
  var phone = true

  if (window.screen.width >= 600) {
    phone = false
  }

  return (
    <>
      <Router>
        <AuthProvider>
          <DbProvider>
            <Routes>
              <Route exact path="/" element={<><Landing phone={phone}/><Form phone={phone}/><ChooseDress/><Presentation/><Footer/></>} />
              <Route path="/inscription" element={<PublicRoute restricted={true}><Signup/></PublicRoute>} />
              <Route path="/connexion" element={<PublicRoute restricted={true}><Login/></PublicRoute>} />
              <Route path="/mot-de-passe-oublie" element={<PublicRoute restricted={true}><ForgotPassword/></PublicRoute>} />
              <Route path="/profil" element={<PrivateRoute><Profile phone={phone}/></PrivateRoute>} />
              <Route path="/admin" element={<AdminRoute><Admin/></AdminRoute>} />
              <Route path="/paiement" element={<PaymentRoute><Payment/></PaymentRoute>} />
            </Routes>
          </DbProvider>
        </AuthProvider>
      </Router>
    </>
  )
}