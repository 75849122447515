import React from "react"
import { UilArrowRight } from "@iconscout/react-unicons"

export default function Footer() {
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-socials">
                    <a href="https://www.instagram.com/jadehaustey" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor"><path d="M556.79,372H443.21A71.21,71.21,0,0,0,372,443.21V556.79A71.21,71.21,0,0,0,443.21,628H556.79A71.21,71.21,0,0,0,628,556.79V443.21A71.21,71.21,0,0,0,556.79,372Zm46.36,182.23a48.92,48.92,0,0,1-48.92,48.92H445.77a48.92,48.92,0,0,1-48.92-48.92V445.77a48.92,48.92,0,0,1,48.92-48.92H554.23a48.92,48.92,0,0,1,48.92,48.92Z" transform="translate(-372 -372)"/><path d="M500,434a66,66,0,1,0,66,66A66,66,0,0,0,500,434Zm0,106.51A40.49,40.49,0,1,1,540.49,500,40.49,40.49,0,0,1,500,540.49Z" transform="translate(-372 -372)"/><circle cx="196.17" cy="59.68" r="16.19"/></svg>
                    </a>
                    <a href="https://www.tiktok.com/@jadehaustey" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.11 511.11" fill="currentColor"><path d="M446.91,128.27v83.8A213.35,213.35,0,0,1,315.8,167.28V353.22A157.81,157.81,0,1,1,180.68,197v85a74.74,74.74,0,1,0,51.95,71.2V0h83.79V1.2A127.08,127.08,0,0,0,443.5,128.27Z"></path></svg>
                    </a>
                </div>
                <a href="mailto:jadehaustey@gmail.com">jadehaustey@gmail.com<UilArrowRight /></a>
            </div>
        </footer>
    )
}
