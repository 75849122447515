import React, { useRef, useState } from "react";
import { UilExclamationTriangle, UilArrowLeft, UilArrowRight } from "@iconscout/react-unicons"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            navigate("/")
        } catch {
            setError("La connexion à votre compte a échoué")
        }

        setLoading(false)
    }

    return (
        <main className="registration-main">
            <Link className="registration-home-link" to="/"><UilArrowLeft /></Link>
            <div className="registration-container">
                <h2>Connexion</h2>
                {error && <p className="error"><UilExclamationTriangle/>{error}</p>}
                <form action="" onSubmit={handleSubmit}>
                    <label>
                        Adresse email
                        <input type="email" name="" id="emailInput" placeholder="Adresse email" ref={emailRef} required/>
                    </label>
                    <label>
                        Mot de passe
                        <input type="password" name="" id="passwordInput" placeholder="Mot de passe" ref={passwordRef} required/>
                        <Link to="/mot-de-passe-oublie">Mot de passe oublié ?</Link>
                    </label>
                    <button disabled={loading} type="submit">Me connecter<UilArrowRight /></button>
                </form>
            </div>
            <div className="registration-link">
                Pas encore de compte ? <Link to="/inscription">M'inscrire<UilArrowRight /></Link>
            </div>
        </main>
    )
}
