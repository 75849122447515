import React, { useRef, useState } from "react";
import { UilExclamationTriangle, UilArrowLeft, UilArrowRight } from "@iconscout/react-unicons"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function Signup() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Les mots de passe ne correspondent pas")
        }

        try {
            setError("")
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            navigate("/connexion")
        } catch {
            setError("La création du compte a échoué")
        }

        setLoading(false)
    }

    return (
        <main className="registration-main">
            <Link className="registration-home-link" to="/"><UilArrowLeft /></Link>
            <div className="registration-container">
                <h2>Inscription</h2>
                {error && <p className="error"><UilExclamationTriangle/>{error}</p>}
                <form action="" onSubmit={handleSubmit}>
                    <label>
                        Adresse email
                        <input type="email" name="" id="emailInput" placeholder="Adresse email" ref={emailRef} required/>
                    </label>
                    <label>
                        Mot de passe
                        <input type="password" name="" id="passwordInput" placeholder="Mot de passe" ref={passwordRef} required/>
                    </label>
                    <label>
                        Confirmation du mot de passe
                        <input type="password" name="" id="passwordConfirmInput" placeholder="Confirmation du mot de passe" ref={passwordConfirmRef} required/>
                    </label>
                    <button disabled={loading} type="submit">M'inscrire<UilArrowRight /></button>
                </form>
            </div>
            <div className="registration-link">
                Déjà un compte ? <Link to="/connexion">Me connecter<UilArrowRight /></Link>
            </div>
        </main>
    )
}
