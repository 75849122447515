import React, { useState, useRef } from "react"
import { UilImageUpload } from "@iconscout/react-unicons"

export default function FileUploader(props) {
    const [filesNames, setFilesNames] = useState([])
    const hiddenFileInput = useRef(null)
    
    const handleClick = event => {
        hiddenFileInput.current.click()
    }

    const handleChange = async (event) => {
        const files = event.target.files
        if (files.length > 1) {
            Array.from(files).forEach((file, index) => {
                if (index === 0) {
                    setFilesNames(`${file.name}`)
                } else {
                    setFilesNames(prevState => prevState + `, ${file.name}`)
                }
            })
        } else {
            setFilesNames(Array.from(files)[0].name)
        }
        props.handleFiles(files)
    }

    return (
        <>
            {filesNames.length !== 0
                ? <button type="button" onClick={handleClick}>
                    <UilImageUpload /><span>{filesNames}</span>
                </button>
                : <button type="button" onClick={handleClick}>
                    <UilImageUpload /><span>Sélectionner des photos</span>
                </button>
            }
            <input type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{display:'none'}} 
                    accept="image/png, image/jpeg"
                    multiple="multiple"
            /> 
        </>
    )
}