import React from "react"
import Portrait from "../images/jade-img.webp"

export default function Presentation() {
    return (
        <div className="presentation">
            <main>
                <img src={Portrait} alt="Portrait" />
                <div className="presentation-text">
                    <h2>Jade</h2>
                    <p>Haustey Couture c'est permettre aux femmes de porter la <span>robe</span> parfaite et unique pour elles. Créée spécifiquement selon leurs critères et leurs mesures.</p>
                </div>
            </main>
        </div>
    )
}
