import React, { useContext } from "react"
import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore"
import { db } from "../firebase"

const DbContext = React.createContext()

export function useDb() {
    return useContext(DbContext)
}

export function DbProvider({ children }) {
    async function getPaymentLink(email) {
        const q = query(collection(db, "payment-links"), where("email", "==", email))

        const queryResult = getDocs(q)

        return queryResult.then(response => {
                    let link = response.docs.map(doc => ({
                        data: doc.data(),
                        id: doc.id,
                    }))
                    return link[0]
                })
    }

    async function setDevis(data, urls) {
        if (data.description === undefined) {
            data.description = ""
        }
        await setDoc(doc(collection(db, "devis")), {
            "Nom et prénom": data.nom,
            "Adresse email": data.email,
            "Pour quelle date" : data.date,
            "Pour quel évènement" : data.evenement,
            "Sa taille habituelle" : data.taille,
            "Longueur de la robe" : data.longueur,
            "Décolleté" : data.decollete,
            "Manches" : data.manches,
            "Jupe" : data.jupe,
            "Hauteur de la taille" : data.hauteurTaille,
            "Couleur(s)" : data.couleur,
            "Tissu(s)" : data.tissu,
            "Description" : data.description,
            "Images": urls 
        })
    }

    const value = {
        getPaymentLink,
        setDevis
    }

    return (
        <DbContext.Provider value={value}>
            {children}
        </DbContext.Provider>
    )
}