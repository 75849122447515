import React from "react"
import Pencil from "../images/choose-dress-img-pencil.png"
import MesuringTape from "../images/choose-dress-img-mesuring-tape.png"
import Fabrics from "../images/choose-dress-img-fabrics.png"

export default function ChooseDress() {
    return (
        <div className="choose-dress">
            <h2>Comment choisir votre <span>robe</span> ?</h2>
            <div className="choose-dress-content-container">
                <div className="choose-dress-img">
                    <img src={Pencil} alt="Crayon"/>
                    <p>On vous dessine un modèle unique selon vos critères.</p>
                </div>
                <div className="choose-dress-img">
                    <img src={MesuringTape} alt="Mètre ruban"/>
                    <p>Il vous suffit juste de prendre vos mesures. On s'occupe du reste.</p>
                </div>
                <div className="choose-dress-img">
                    <img src={Fabrics} alt="Tissus"/>
                    <p>On choisit ensemble le tissu parfait pour votre robe.</p>
                </div>
            </div>
        </div>
    )
}
