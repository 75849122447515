import React, { useRef, useEffect, useState } from "react"
import { gsap } from "gsap"
import { UilCheck, UilArrowRight } from "@iconscout/react-unicons"
import { format } from "date-fns"
import { useDb } from "../contexts/DbContext"
import FileUploader from "./FileUploader"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { storage } from "../firebase"
import { v4 } from "uuid"

const useEffectOnlyOnUpdate = (callback, dependencies) => {
    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) {
            callback(dependencies)
        } else {
            didMount.current = true
        }
    }, [callback, dependencies])
}

export default function Form(props) {
    const { setDevis } = useDb()
    const [isClicked, setIsClicked] = useState(false)
    const [formState, setFormState] = useState(false)
    const [formInfos, setFormInfos] = useState({})
    const [files, setFiles] = useState([])
    const [fileUrls, setFileUrls] = useState(null)
    const [isSent, setIsSent] = useState(false)
    const [isSentFile, setIsSentFile] = useState(false)

    function handleFiles(files) {
        setFiles(Array.from(files))
    }

    function handleChange(event) {
        setFormInfos({...formInfos, [event.target.name]: event.target.value})
    }

    function sendDevis(data) {
        setDevis(formInfos, data)
            .then(() => {
                document.querySelectorAll(".form form>*").forEach(element => {
                    gsap.to(element, {
                        opacity: 0, 
                        duration: .3,
                        onComplete: () => {
                            setIsSent(true)
                        }
                    })
                })
            })
    }

    function handleSubmit(event) {
        event.preventDefault()
        if (files === []) {
            sendDevis("")
        } else {
            setFileUrls([])
            files.forEach(file => {
                const imageRef = ref(storage, `images/${file.name + v4()}`)
                uploadBytes(imageRef, file).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then(url => {
                        setFileUrls(prev => [...prev, url])
                    })
                })
            })
        }
    }

    const date = new Date()

    const today = format(date, "yyyy-MM-dd")

	useEffectOnlyOnUpdate((dependencies) => {
        if (props.phone) {
            if (document.querySelector(".form-container") != null) {
                gsap.to(".form-container", {
                    width: "100%",
                    maxWidth: "100%",
                    height: "100%",
                    borderRadius: "0",
                    duration: .3
                })
                gsap.to(".form-container h2, .form-container button", {
                    opacity: 0,
                    duration: .3,
                    onComplete: () => {
                        setFormState(true)
                    }
                })
            } if (document.querySelector(".form-message-container") != null) {
                gsap.from(".form-message-container", {
                    width: "100%",
                    maxWidth: "100%",
                    height: "100%",
                    borderRadius: "0",
                    duration: .3,
                    onComplete: () => {
                        gsap.to(".form-message-container svg, .form-message-container p", {
                            opacity: 1,
                            duration: .3,
                        })
                    }
                })
            } else {
                document.querySelectorAll(".form form>*").forEach(element => {
                    gsap.to(element, {
                        opacity: 1, 
                        duration: .3
                    })
                })
            }
        } else {
            if (document.querySelector(".form-container") != null) {
                gsap.to(".form-container", {
                    width: "80%",
                    maxWidth: "600px",
                    height: "90%",
                    duration: .3
                })
                gsap.to(".form-container h2, .form-container button", {
                    opacity: 0,
                    duration: .3,
                    onComplete: () => {
                        setFormState(true)
                    }
                })
            } if (document.querySelector(".form-message-container") != null) {
                gsap.from(".form-message-container", {
                    width: "80%",
                    maxWidth: "600px",
                    height: "90%",
                    duration: .3,
                    onComplete: () => {
                        gsap.to(".form-message-container svg, .form-message-container p", {
                            opacity: 1,
                            duration: .3,
                        })
                    } 
                })
            } else {
                document.querySelectorAll(".form form>*").forEach(element => {
                    gsap.to(element, {
                        opacity: 1, 
                        duration: .3
                    })
                })
            }
        }
        if (fileUrls !== null) {
            if (files.length === fileUrls.length) {
                if (!isSentFile) {
                    sendDevis(fileUrls)
                    setIsSentFile(true)
                }
            }
        }
    }, [isClicked, fileUrls])

    return (
        <div className="form">
            {isSent  
                ? <div className="form-message-container">
                    <UilCheck/>
                    <p>Votre message a bien été envoyé. Nous reviendrons vers vous par mail très prochainement.</p>
                </div>
                : <> {
                    formState 
                    ? 
                    <form onSubmit={handleSubmit}>
                        <label>Ton nom et prénom
                            <input name="nom" type="text" placeholder="Ton nom et prénom" onChange={handleChange} required/>
                        </label>

                        <label>Ton adresse email
                            <input name="email" type="email" placeholder="Ton adresse email" onChange={handleChange} required/>
                        </label>

                        <label>Pour quelle date ? 
                            <input name="date" type="date" min={today} onChange={handleChange} required/>
                        </label>

                        <label>Pour quel évènement ? 
                            <input name="evenement" type="text" placeholder="Pour quel évènement ?" onChange={handleChange} required/>
                        </label>

                        <label>Ta taille habituelle
                            <select name="taille" onChange={handleChange} required>
                                <option value="">— Ta taille habituelle —</option>
                                <option value="XXS">XXS</option>
                                <option value="XS">XS</option>
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                                <option value="XXL">XXL</option>
                            </select>
                        </label>

                        <label>Longueur de la robe
                            <select name="longueur" onChange={handleChange} required>
                                <option value="">— Longueur de la robe —</option>
                                <option value="Courte">Courte</option>
                                <option value="Mi-longue">Mi-longue</option>
                                <option value="Longue">Longue</option>
                            </select>
                        </label>

                        <label>Décolleté
                            <select name="decollete" onChange={handleChange} required>
                                <option value="">— Décolleté —</option>
                                <option value="Plongeant">Plongeant</option>
                                <option value="Classique">Classique</option>
                                <option value="Bustier">Bustier</option>
                                <option value="Rond">Rond</option>
                                <option value="Col montant">Col montant</option>
                            </select>
                        </label>

                        <label>Manches
                            <select name="manches" onChange={handleChange} required>
                                <option value="">— Manches —</option>
                                <option value="Bretelles">Bretelles</option>
                                <option value="Courtes">Courtes</option>
                                <option value="Longues">Longues</option>
                                <option value="Bouffantes">Bouffantes</option>
                                <option value="Sans manches">Sans manches</option>
                            </select>
                        </label>

                        <label>Jupe
                            <select name="jupe" onChange={handleChange} required>
                                <option value="">— Jupe —</option>
                                <option value="Droite">Droite</option>
                                <option value="Crayon">Crayon</option>
                                <option value="Évasée">Évasée</option>
                                <option value="Volumineuse">Volumineuse</option>
                                <option value="Assymétrique">Assymétrique</option>
                            </select>
                        </label>

                        <label>Hauteur de la taille
                            <select name="hauteurTaille" onChange={handleChange} required>
                                <option value="">— Hauteur de la taille —</option>
                                <option value="Basse">Basse</option>
                                <option value="Moyenne">Moyenne</option>
                                <option value="Haute">Haute</option>
                            </select>
                        </label>

                        <label>Couleur(s)
                            <input name="couleur" type="text" placeholder="Couleur(s)" onChange={handleChange} required/>
                        </label>

                        <label>Tissu(s)
                            <input name="tissu" type="text" placeholder="Tissu(s)" onChange={handleChange} required/>
                        </label>

                        <textarea name="description" placeholder="Décrivez votre robe plus en détails..." onChange={handleChange}/>

                        <label>Des photos d'inspiration ? 
                            <FileUploader handleFiles={handleFiles} />
                        </label>
                        
                        <button type="submit">Envoyer<UilArrowRight /></button>
                    </form>
                    :
                    <div className="form-container">
                        <h2><span>Créer</span><br/>la <span>robe</span> de vos rêves</h2>
                        <button onClick={() => setIsClicked(true)}>Cliquez ici <span><UilArrowRight /></span></button>
                    </div>
                } </>
            }
        </div>
    )
}